<template>    
  <v-dialog v-model="dialog.on" persistent max-width="290">
    <v-card>
      <v-card-title class="text-h5">{{dialog.hl}}</v-card-title>
      <v-card-text>{{dialog.txt}}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text     @click="goto('no')" v-if="dialog.no">{{dialog.no}}</v-btn>
        <v-btn color="error" depressed  @click="goto('yes')">{{dialog.yes}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    dialog: Object,
  },
  methods:{
    goto(a){
      this.dialog.on=false;
      if(a=='yes') this.$emit('action', this.dialog.cb );
      else this.dialog.fired=true;
    },
  }
}
</script>