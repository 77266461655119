


import Vue from 'vue'
import App from './App.vue'

import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify, {iconfont: 'mdi'});
Vue.config.productionTip = false
Vue.config.devtools = false

new Vue({
  vuetify: new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#604fc1',     // lila
          secondary: '#3ab391',   // grünlich 
          accent: '#82B1FF',      // graublau
          error: '#d35221',       // hellrot
          info: '#37ffc8',        // cyan-neon-headline
          success: '#4CAF50',     // grün
          warning: '#FFC107',     // gelb
        },
      },
    },
  }),
  render: h => h(App),
}).$mount('#app')
