const fakeplayer = [
  {name: 'Henry Bauer', avatar: 'https://robohash.org/15?set=set2',ts:998834,id:12},
  {name: 'Anika Goor', avatar: 'https://robohash.org/125?set=set1',ts:998834,id:12},
  {name: 'Honk Eeal', avatar: 'https://robohash.org/15?set=set3',ts:998834,id:12},
];

const cards = [[
  'Ein Urlaub ohne ins Internet gehen zu können, ist für mich…',
  'Ich halte es für (nicht) richtig, dass es keine gesetzliche Beschränkung für die Dauer gibt, die Kinder oder Jugendliche im Internet verbringen, weil…',
  'Im Umgang mit digitalen Medien sollte man folgende Regeln beachten…',
  'Ich habe (nicht) genug Zeit für Sport, Hobbys, Freunde, Schule, weil…',
  'Eine Woche ohne PC, Smartphone, Internet,…, wäre für mich…',
  'Im Internet Einkaufen ist für mich…',
  'Ich bleibe oft/selten/nie länger, als ich mir vorgenommen habe, im Internet, weil…',
  'Körperlich kann man merken, dass man zu viel am Computer verbringt, wenn…',
  'Mit Langeweile kann ich (nicht) gut umgehen, weil…',
  'Eltern sollten ihren Kindern (auf keinen Fall) früh beibringen mit Multimediageräten wie Handy/Internet/Fernseher/…  umzugehen, weil…',
  'Zum Thema Internet und Pornographie fällt mir Folgendes ein…',
  'Wenn ein Freund/eine Freundin zu viel spielt/chattet, merke ich das daran, dass…',
  'Pornos haben (viel) wenig mit der realen Sexualität zwischen Erwachsenen zu tun, weil…',
  'Über illegales Herunterladen von Musik, Videos, Filmen, Spielen,…denke ich, dass…',
  'Wenn ich Fotos/Videos von mir ins Internet stelle, achte ich darauf, dass…',
  'Wenn ich beim Surfen auf Sex-Seiten treffe, dann… ',
  'Ständig erreichbar zu sein ist für mich…',
  'Nach dem Hobby oder Sport fühle ich mich (nicht) anders als nach ausgiebigem Chatten oder Computerspiel, weil…',
  'Ich habe (keine) Angst etwas zu verpassen, wenn ich nicht online bin, weil…',
  'Ich habe mir schon mal (noch nie) Gedanken darüber gemacht, ob ich zu viel im Netz unterwegs bin…',
  'Ich denke (oft) selten über meinen Umgang mit digitalen Medien nach, weil…',
  'In Bezug auf Smartphone/ Handy/ Internet/Computer würde mich folgendes noch interessieren…',
  'Influencerinnen und Influencer finde ich …',
  'Wenn ich merke, dass ein Freund/eine Freundin zu viel Zeit mit Zocken oder Instagram und Co. verbringt, würde ich …',
  'Binge Watching (Serienmarathon) finde ich …',
  'Die automatische Wiedergabe von Videos z.B. bei YouTube (Autoplay) finde ich …',
],[
  'Man sagt, dass Bewegungsmangel auch eine Folge von zu viel Computerspielen ist. Darüber denke ich…',
  'Wenn ich auf meinem Computer nicht mehr die neuesten Spiele spielen kann, dann…',
  'Wenn ich weiß, dass mein Freund/meine Freundin mich versetzt, damit er/sie die Zeit vor dem Computer verbringen kann, reagiere ich darauf, indem ich…',
  'Es ist (gut) schlecht, dass das Jugendschutzgesetz Altersgrenzen für Computerspiele vorgibt, weil…',
  'Wenn meine Freunde mit mir ein für mein Alter nicht zugelassenes Computerspiel spielen wollen, würde ich…',
  'Ein Junge, der bei „Baller-Spielen“ “abloost“, ist …',
  'Es sind (nicht) alle gleich gefährdet, computerabhängig zu werden, weil…',
  'Ich bin (nicht) gefährdet von Computerspielen abhängig zu werden, weil…',
  'Als computersüchtig würde ich jemanden bezeichnen, der…',
  'Meine Freunde würden über mein Computerspielen sagen…',
  'Wenn jemand computersüchtig ist, betrifft das (nicht) nur ihn, weil…',
  'Computerspielsucht ist (nicht) vergleichbar mit der Abhängigkeit von „richtigen“ Drogen, weil…',
  'Ich würde aufhören Computer zu spielen, wenn…',
  'Ich investiere (viel) wenig Geld für Bildschirmspiele, weil…',
  'Ich glaube, (Mädchen) Jungen spielen mehr Ballerspiele, weil…',
  'Ich glaube, dass es (einen) keinen Zusammenhang zwischen dem Spielen von Ego-Shootern und realer Gewalt gibt... ',
  'Was mir beim Spielen von Ego-Shootern (keinen) Spaß macht ist…',
  'Vor Computersucht schützt z.B. …',
  'Wenn ein(e) Freund(in) von mir computersüchtig wäre, würde ich…',
  'Wenn meine Eltern mir das Spielen am Computer verbieten würden, würde ich…',
  'Meine Eltern machen sich (keine) Sorgen,     dass ich zu lange spiele, weil…',
  'Viel Computerspielen und eine Liebesbeziehung vertragen sich (nicht), weil…',
  'Es ist (riskant) unproblematisch eine Zeit lang mal total viel zu spielen, weil…',
  'Ich spiele nie/selten/über Monate hinweg zu viel, weil…',
  'Eine Nacht durchzuspielen ist für mich (nicht) normal, weil…',
  'Die Spiele-Welt ist für mich (nicht) interessanter als die reale Welt, weil…',
  'In meinem Freundeskreis spielen Computerspiele eine (keine) große Rolle, weil…',
  'Mir gefällt es (nicht), in online-Spielen eine andere Rolle einzunehmen, weil…',
  'Wenn ich Computerspiele spiele, kann ich alles um mich herum vergessen, weil…',
  'Wenn ich zu viel Computer spiele, merke ich das daran, dass…',
  'Beim Spielen kann ich Stress und Frust vergessen, weil…',
  'Ich habe beim Computerspielen (nicht) viele Erfolgserlebnisse, weil…',
  'Etwas, was mir mindestens genau so viel Spaß macht, wie das Spielen am Computer ist,…',
  'Ich verberge (nie) manchmal vor anderen wie viel ich spiele, weil…',
  'Ich mache mir (nie) manchmal Gedanken darüber, ob ich zu viel zocke, weil…',
  'Wenn ich ein Computerspiel spiele, fällt es mir (nicht) schwer wieder aufzuhören, weil…',
  'Etwas was ich gut kann, ist…',
  'Wenn ich viel Zeit vor dem Computer verbringe, beeinflusst das andere Dinge (nicht), weil…',
  'Das Tolle (das Problem) daran bei einer Gilde oder einem Clan dabei zu sein, ist…',
  'Problematisch wird das Computerspielen, wenn…',
  'Ich denke … Stunden am Tag vor dem Computer sind noch o.k., weil…',
  'Mein liebstes Computerspiel ist für mich…, weil…',
  'Mit anderen zu „daddeln“ reizt mich (nicht), weil…',
  'Durch Computerspiele kann man etwas (nichts) lernen, weil…',
  'Um beim Spielen durchzuhalten, würde ich Energie-Drinks oder Gaming Booster (nicht) einsetzen, weil … ',
],[
  'Leute, die in sozialen Netzwerken aktiv sind, sind es offline (nicht so) auch, weil…',
  'Ich glaube (Jungen) Mädchen sind mehr in sozialen Netzwerken unterwegs, weil…',
  'Man sagt, dass einsame Menschen durch soziale Netzwerke in der realen Welt immer mehr vereinsamen, das kann ich mir (nicht) vorstellen, weil… ',
  'Im Netz kann man meist offener sein, als im direkten Kontakt, weil…',
  'Meine schlechteste Erfahrung in sozialen Netzwerken war…',
  'Meine schönste Erfahrung in sozialen Netzwerken war…',
  'Probleme mit sozialen Netzwerken könnte es geben, wenn…',
  'Der Unterschied zwischen Freunden, die ich nur aus dem Netz kenne und meinen Freunden, mit denen ich auch offline zu tun habe, ist…',
  'Die Vorteile von sozialen Netzwerken sind…',
  'ich finde es (nicht) wichtig, viele online Freunde zu haben, weil…',
  'Ich stöbere (nicht) gerne in den Profilen von anderen, weil…',
  'Ich nutze den „gefällt-mir-Button“(nicht) oft, weil…',
  'Ich finde es (un)problematisch, ein Foto von mir online zu stellen, weil…',
  'Ich würde einer Internetbekanntschaft folgende privaten Daten (nicht)  geben geben…',
  'Mir ist meine Privatsphäre im Netz (nicht so) wichtig, weil…',
  'Ich verbringe täglich… Stunden im Internet.',
  'Die (Nachteile) Vorteile, sich über das Internet kennenzulernen sind… ',
  'Ich finde es (nicht) gefährlich, Menschen über das Internet kennenzulernen, weil…',
  'Es gibt Daten, die ich nie im Internet preisgebe, z.B. …',
  'Man kann durch soziale Netzwerke besser Freundschaften erhalten, weil…',
  'Am meisten kommuniziere ich mit Leuten, die…',
  'soziale Netzwerke erleichtern es, neue Menschen kennenzulernen, weil…',
  'Man kann (nicht) ohne soziale Netzwerke auskommen, weil…',
  'Sich in sozialen Netzwerken darzustellen, finde ich…',
  'In sozialen Netzwerken kann man (nicht) leicht Gleichgesinnte treffen und sich über alles Mögliche austauschen, weil… ',
  'Ich finde es (nicht so) toll, mich in sozialen Netzwerken ausprobieren zu können und Reaktionen darauf zu bekommen, weil…',
  'Ich chatte /blocke/poste (nicht), weil…',
  'Ich finde es (nicht) gut, Eltern als Freunde in Facebook zu haben, weil…',
  'Über Fake-Profile denke ich…',
  'Ich weiß (nicht) genau, wer mein Profil lesen kann, weil…',
  'Ich denke, dass es (ein/kein) Problem ist, dass meine Daten von den Betreibern sozialer Netzwerke gesammelt werden, weil…',
  'Der Preis für soziale Netzwerke sind meine Daten. Für eine Nutzung, die meine Daten sicher macht, würde ich (nicht) zahlen, weil…',
  'Über Werbung in sozialen Netzwerken denke ich…',
  'Eine Folge davon, ständig in sozialen Netzwerken unterwegs zu sein, könnte z.B. … sein.',
  'Wenn ich aufwache, checke ich (nicht) als erstes, ob ich neue Nachrichten bekommen habe, weil…',
  'Ich habe (nicht) das Gefühl, dass ich zu viel Zeit im Netz verbringe, weil… ',
  'Ich habe (selten) schon oft mehr Zeit in sozialen Netzwerken verbracht, als ich wollte, weil…',
  'Ich habe schon mal (noch nie) Probleme bekommen, weil ich zu lange in sozialen Netzwerken unterwegs war, weil…',
  'Wenn ich all die tollen Profile der anderen sehe, denke/fühle ich mich…',
  'Jemand, der kein soziales Netzwerk oder/und Apps nutzt, ist für mich…',
  'Viele LIKES zu haben ist für mich…',
  'Es ist fast (schon) nicht möglich, nicht bei sozialen Netzwerken dabei zu sein, weil…',
  'Über Leute, die anderen fiese Fotokommentare hinterlassen, denke ich…',
  'Ich finde es (un)cool, wenn andere Fotos, Videos von mir ins Internet stellen, weil…',
  'Wenn mir jemand einen fiesen Kommentar postet, dann reagiere ich darauf , indem ich …',
  'Ich finde es (nicht) o.k. negative Einträge auf Pinnwände oder auch mal abfällige Kommentare zu Fotos zu machen, weil…',
  'Bei einer „Hassgruppe“ würde ich (nicht) mitmachen, weil…',
  'Über jemanden herzuziehen kann Spaß machen, weil…',
  'Es soll Leute geben, die von sozialen Netzwerken abhängig geworden sind, das kann ich mir (nicht) vorstellen, weil…',
  'Ob jemand von sozialen Netzwerken Abhängig ist kann man daran erkennen, das…',
  'Durch Social Media wird kein/ein falsches Schönheitsideal vermittelt, weil …',
  'Ich denke, man kann schon/nicht von Social Media abhängig werden, weil …',
  'Über Beautyfilter (für Selfies) denke ich, …',
  'Das immer mehr online läuft finde ich …',
  'Vor und Nachteile von online und offline- Treffen sind für mich …',
],[
  'Ich finde Schüler/-innen sollten ihre Handyrechnung (nicht) selbst bezahlen müssen, weil…',
  'In-App-Käufe finde ich…',
  'Eine Woche ohne Smartphone wäre für mich…',
  'In der Öffentlichkeit zu telefonieren finde ich…',
  'Ein Leben ohne Smartphone kann ich mir nicht vorstellen, weil… ',
  'Das aktuelle Smartphone zu haben, bedeutet für mich…',
  'Ich mache mein Smartphone (nie) schon aus, weil…',
  'Wenn ich einem Freund/einer Freundin etwas Wichtiges erzähle und sie ständig mit dem Smartphone beschäftigt ist, dann…',
  'Die wichtigsten Dinge, die ich mit meinem Smartphone mache, sind…',
  'Wenn ich mein Smartphone vergessen hätte, würde ich eine/n Freund/-in (nicht) warten lassen um es zu holen, weil…',
  'Wenn ich mit Freunden unterwegs bin, nutze ich mein Smartphone (nicht) weil,…',
  'Ich habe schon mal/noch nie bewusst auf die Zeit geachtet, die ich am Smartphone verbringe, weil … ',
  'Meine Lieblings-App ist…',
  'Ich mache mein Smartphone vor dem Einschlafen (nicht) aus, weil…',
  'Von „digitalem Fasten“ halte ich...',
],[
  'Ich habe schon mal/noch nie Geld für Lootboxen ausgegeben, weil …',
  'Ich habe schon mal/noch nicht an Glücksspielen im Internet teilgenommen, weil…',
  'Onlinepoker finde ich…',
  'Dass Jugendliche im Netz keine Glücksspiele spielen dürfen, finde ich…',
  'Ich glaube, dass bei Onlineglücksspielen betrogen/nicht betrogen wird, weil…',
  'Glücksspiele im Internet können süchtig machen/ können nicht süchtig machen, weil…',
  'Der Unterschied zwischen einer Pokerrunde mit Freunden und Onlinepoker ist…',
  'Man kann mit Sportwetten im Internet Geld verdienen/kein Geld verdienen, weil…',
  'Ich würde online auf meine Lieblingsmannschaft setzen / nicht auf meine Lieblingsmannschaft setzen, weil…',
  'Sportwetten online finde ich…',
  'Wenn ich beim Onlinepoker meinen Gewinn nicht ausbezahlt bekomme, dann…',
  'Wenn jemand online Geld einsetzt, würde ich ihm raten…',
  'Onlinecasinos finde ich…',
],[
  'Kartenspiele finde ich ...'
]];


export  { fakeplayer, cards }