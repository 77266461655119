<template>
 <div>
  <v-subheader v-if="data.cardinfo.cardstack.length>1" class="acid ml-n4">Noch {{data.cardinfo.cardstack.length}} Karten übrig</v-subheader>
  <v-subheader v-else-if="data.cardinfo.cardstack.length==1" class="acid ml-n4">Noch eine Karte übrig</v-subheader>
  <v-subheader v-else class="acid ml-n4">Ergebnisse</v-subheader>
  
  <v-simple-table fixed-header height="395px" class="pritable" style="border: black solid 1px;">
    <template v-slot:default>
      <thead>
        <tr @click=" data.debug ? socket.emit('goto_lottery', data.cardinfo.cardstack) : ()=>{} ">
          <th class="text-left pritable">&nbsp;</th>
          <th class="text-left pritable">Spieler</th>
          <th class="text-left pritable">Karte</th>
          <th class="text-left pritable">Dauer</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in data.score" :key="item.ts">
          <td>
            <v-avatar size="33">
              <img :src="item.avatar" style="border:black 1px solid; border-radius:50%; background-color:#fff" />
            </v-avatar>
          </td>
          <td>{{ item.name }} <span v-show="data.debug"> {{ new Date(item.ts).toLocaleTimeString('de-De') }}</span></td>
          <td>{{ item.card }}</td>
          <td>{{ item.timer }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
 </div>
</template>

<script>

export default {
  name: 'Score',
  props: {
    data: Object,
    socket: Object,
  },
  created() {
    this.data.score.sort((a, b) => (a.timer < b.timer) ? 1 : -1);
  },
  methods:{
    goto(here){
      this.$emit('goto',here)
    },
  }
}
</script>

<style scoped> 
.pritable{
   background-color:#673ab7 !important;
   color:#37ffc8 !important;
   border-radius:8px;
}
.pritable:hover{
   color:#000 !important;
}
</style>
