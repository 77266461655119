<template>
  <div class="block preset-grid">
    <p class="mt-2 acid">Einstellungen</p>
    <div style="position:absolute; top:24px; right:20px;">
      <v-btn small @click="goto('menu')" class="acid-btn">
        <v-icon dark left>mdi-menu-left-outline</v-icon> ZURÜCK
      </v-btn>
    </div>
    <div style="height:395px" class="mt-n2 overflow-y-auto overflow-x-hidden">
      <v-card style="border:1px solid black !important; border-radius:5px;" outlined>
        <v-tabs v-model="tab" background-color="purple darken-4" centered dark icons center-active>
                <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
          <v-tab href="#tab-0"><v-icon>mdi-cog</v-icon></v-tab>
          <v-tab href="#tab-1"><v-icon>mdi-web</v-icon></v-tab>
          <v-tab href="#tab-2"><v-icon>mdi-controller-classic</v-icon></v-tab>
          <v-tab href="#tab-3"><v-icon>mdi-message</v-icon></v-tab>
          <v-tab href="#tab-4"><v-icon>mdi-cellphone</v-icon></v-tab>
          <v-tab href="#tab-5"><v-icon>mdi-chess-queen</v-icon></v-tab>
          <v-tab href="#tab-6"><v-icon>mdi-stack-exchange</v-icon></v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab" style="background-color:black">
          <v-tab-item value="tab-0">
            <v-card flat color="deep-purple" class="tabcard overflow-y-auto overflow-x-hidden">
              <v-card-text>
                <v-sheet color="white" elevation="1" rounded class="pa-4">
                  <v-slider @change="userlimit" color="primary" v-model="data.maxPlayer" :label="'Bis zu '+data.maxPlayer+' Teilnehmer'" inverse-label max="99" min="2"></v-slider>
                  <v-switch color="primary" v-model="data.banning" :label=" data.banning?'Spieler können verbannt werden':'Verbannen ausgeschaltet' "></v-switch>
                  <v-switch color="primary" @click=" socket.emit('score_inbetween', data.showScore ) " v-model="data.showScore" :label=" data.showScore?'Zusammenfassung nach jeder Karte':'Zusammenfassung erst am Ende' "></v-switch>
                  <v-divider class="my-1"></v-divider>
                  Karten, die seit Raumeröffnung bereits aufgedeckt wurden, werden dem Stapel entnommen.
                  <v-btn @click=" socket.emit('reset_score');goto('menu')  " color="primary" class="mt-1" block>Gespielte Karten zurücksetzen</v-btn>
                </v-sheet>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-1">
            <v-card flat color="deep-purple" class="tabcard overflow-y-auto overflow-x-hidden">
              <v-card-text>
                <h4 class="vac-h4 mb-4">Allgemein</h4>
                <v-autocomplete dark item-color="deep-purple" outlined chips small-chips multiple
                  v-model="s1" :items="items[0]" @change="chx"
                  :label="s1.length>0?'Weitere wählen...':'Alle ausgewählt'"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card flat color="deep-purple" class="tabcard overflow-y-auto overflow-x-hidden">
              <v-card-text>
                <h4 class="vac-h4 mb-4">Digitale Spiele</h4>
                <v-autocomplete dark item-color="deep-purple" outlined chips small-chips multiple
                  v-model="s2" :items="items[1]" @change="chx"
                  :label="s2.length>0?'Weitere wählen...':'Alle ausgewählt'"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-3">
            <v-card flat color="deep-purple" class="tabcard overflow-y-auto overflow-x-hidden">
              <v-card-text>
                <h4 class="vac-h4 mb-4">Soziale Medien</h4>
                <v-autocomplete dark item-color="deep-purple" outlined chips small-chips multiple
                  v-model="s3" :items="items[2]" @change="chx"
                  :label="s3.length>0?'Weitere wählen...':'Alle ausgewählt'"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-4">
            <v-card flat color="deep-purple" class="tabcard overflow-y-auto overflow-x-hidden">
              <v-card-text>
                <h4 class="vac-h4 mb-4">Smartphone</h4>
                <v-autocomplete dark item-color="deep-purple" outlined chips small-chips multiple
                  v-model="s4" :items="items[3]" @change="chx"
                  :label="s4.length>0?'Weitere wählen...':'Alle ausgewählt'"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-5">
            <v-card flat color="deep-purple" class="tabcard overflow-y-auto overflow-x-hidden">
              <v-card-text>
                <h4 class="vac-h4 mb-4">Glücksspiel</h4>
                <v-autocomplete dark item-color="deep-purple" outlined chips small-chips multiple
                  v-model="s5" :items="items[4]" @change="chx"
                  :label="s5.length>0?'Weitere wählen...':'Alle ausgewählt'"
                ></v-autocomplete>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-6">
            <v-card flat color="deep-purple" class="tabcard overflow-y-auto overflow-x-hidden">
              <v-card-text>                
                <p class="acid mt-4" style="font-size:12px !important;">Karten anlegen</p>
                <v-textarea filled v-model="nlCardTxt" @change="txt2arr" color="#000" background-color="#fff" rows="7"
                  label="Texte durch Zeilenumbruch (ENTER) trennen."
                ></v-textarea>
                  
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Setup',
	data() {
		return {
      tab: null,
      items:[],
			s1: [], s2: [], s3: [], s4: [], s5: [], s6: [], nlCardTxt: '', cardTxtArr: [],
		}
	},
  props: {
    data: Object,
    socket: Object,
  },
  created() {
    
    for(let i=0; i<6; i++){
      this.items[i] = [];
      this.data.cards[i].forEach((el,idx) => { 
        this.items[i].push(el);
      });

      this.data.cardset[i].forEach((el,idx) => { 
        this['s'+ (i+1)].push(el);
      });
    }

    this.nlCardTxt = this.arr2txt(this.s6);
  },
  computed:{
    dynOpt(){
      return this.items[5];
    }
  },
  methods:{
    arr2txt(arr){
      return arr.join('\r\n');
    },
    txt2arr(){
      this.cardTxtArr = this.nlCardTxt.split("\n");
      if(this.cardTxtArr.length > 1){
        this.cardTxtArr = this.cardTxtArr.filter(function(e){return e});
        
        this.items[5] = [];
        this.cardTxtArr.forEach((el,idx) => {
          this.items[5].push(el);
        });
        this.s6 = this.items[5];
        this.chx(); 
        
      }
    },
    chx(val){

      let sArr = [this.s1, this.s2, this.s3, this.s4, this.s5, this.s6];
      

      for(let i=0; i<6; i++){    
        if(sArr[i].length < 1){  
          sArr[i] = this.data.cards[i];
        } else {
          let tmpArr=[];
          sArr[i].forEach( el => {
            tmpArr.push(el); 
          });
          sArr[i]=tmpArr;
        }
      }

      this.data.cardset = sArr;
      this.$emit('upd',this.data)
      
    },
    goto(here){
      this.$emit('goto',here)
    },
    resetCards(){
      if(confirm('Bist du sicher, dass du den Kartenstapel zurücksetzen möchtest?')) {
        this.data.cardset = [ 
          this.data.cards[0],
          this.data.cards[1],
          this.data.cards[2],
          this.data.cards[3],
          this.data.cards[4],
          this.data.cards[5],
        ];
        this.$emit('upd',this.data)
      }
    },
    userlimit(){
      this.socket.emit('set_userlimit',this.data.maxPlayer);
    },
  }
}
</script>

<style lang="scss"> 

@import '~@politico/vue-accessible-selects/styles';

@include selects();
@include select-single();
@include select-multi();

.vue-accessible-select-multi .selected-option-pill{
  background-color: #fff;
  color: #000;
  border-radius: 6px;
  margin-right: 0px;
  font-family: unset;
  font-weight: normal;
  font-size: 12px;
  text-transform: none;
}
.vue-accessible-select-single .combo-label, .vue-accessible-select-multi .combo-label, .vac-h4 {
  color: #37ffc8;
  text-shadow: 2px 0 2px black, -2px 0 2px black, 0 2px 2px black, 0 -2px 2px black;
}
.tabcard{
  height:345px;
}
</style>
