<template>
  <v-bottom-navigation color="orange" :value="menu" style="background-color:transparent;">
    <v-btn value="3" @click="menuNum(3)">
      <span>Menü</span>
      <v-icon>mdi-image</v-icon>
    </v-btn>
    <v-btn value="4" @click="menuNum(4)">
      <span>Lotterie</span>
      <v-icon>mdi-account</v-icon>
    </v-btn>
    <v-btn value="5" @click="menuNum(5)">
      <span>Ziehen</span>
      <v-icon>mdi-cards</v-icon>
    </v-btn>
    <v-btn value="6" @click="menuNum(6)">
      <span>Karte</span>
      <v-icon>mdi-card</v-icon>
    </v-btn>
    <v-btn value="9" @click="menuNum(8)">
      <span>score</span>
      <v-icon>mdi-seal</v-icon>
    </v-btn>
    <v-btn value="8" @click="menuNum(0)">
      <span>Logout</span>
      <v-icon>mdi-power</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script> 
export default {
  name: 'BottomNav',
  data() {
    return {
     
    }
  },
  props: {
    menu: String, 
  },
  created() {
 
  },
  methods:{
    menuNum(n){
      this.$emit('goto', n)
    },

  },
}
</script>

<style>
</style>
