<template>
  <div>
    <Auth v-if="menu==0" @login="login" 
      :socket="socket" :autologin=" getQueryParameterByName('autologin') "
      :hl="data.allowCreate ? (data.ioRoom ? 'Spiel beitreten ('+data.ioRoom+')' : 'Neues Spiel erstellen') : 'Lizenzschlüssel ungültig'" 
      :admin=" (!data.ioRoom)?true:false " :data="data" 
    />
    <v-app id="app" class="noselect" v-else>
      <TopMenu v-if="!data.debug" />
      <v-app-bar app color="yellow" dense v-else>
        <v-btn @click="db" icon><v-icon>mdi-cog</v-icon></v-btn> 
        <v-btn @click="addFakePlayer" icon><v-icon>mdi-account</v-icon></v-btn> 
        <v-btn @click="db('openlink')" icon><v-icon>mdi-link</v-icon></v-btn> 
        <v-toolbar-title>{{data.you.name}} in Room: {{data.ioRoom}}</v-toolbar-title>
      </v-app-bar>
      <v-main>
        <v-container class="bg" fill-height fluid>
          <v-row justify="center" class="ma-1">
            <v-col xs="12" md="10" lg="8">
              <v-card rounded="lg" class="card-login" style="max-width:1200px; background-color:#ffffff17;">                              
                <div class="px-5 pb-6">
                  <v-row justify="center">
                    <v-col cols="12" sm="4">
                      <v-subheader class="acid ml-n2">{{data.playerList.length}} Teilnehmer</v-subheader>
                      <Player @goto="goto" @upd="upd" :data="data" :socket="socket" />
                      <v-btn v-if="data.isAdmin" block small color="warning" class="mt-2" @click="snippetCopy();">
                        <v-icon dark left>mdi-link-variant</v-icon> Einladen
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="8" style="min-width:440px;">
                      <div v-if="data.isAdmin" style="position:absolute; top:22px; right:28px;">
                        <v-btn @click="goto('logout')" x-small icon class="shadow-box" color="black">
                          <v-icon>mdi-power</v-icon>
                        </v-btn>
                      </div>
                      <Menu     v-if="menu==3" @goto="goto" @upd="upd" :data="data" :socket="socket" />
                      <Lottery  v-if="menu==4" @goto="goto" @upd="upd" :data="data" :socket="socket" />
                      <Cards    v-if="menu==5" @goto="goto" @upd="upd" :data="data" :socket="socket" ref="cards" />
                      <Card     v-if="menu==6" @goto="goto" @upd="upd" :data="data" :socket="socket" ref="card"  />
                      <Score    v-if="menu==8" @goto="goto" @upd="upd" :data="data" :socket="socket" />
                      <Setup    v-if="menu==7" @goto="goto" @upd="upd" :data="data" :socket="socket" />
                      <Wait     v-if="menu==9" @goto="goto" @upd="upd" :data="data" :socket="socket" />
                    </v-col>                    
                  </v-row>
                </div>
              </v-card>
              <div v-if="data.debug">{{debugStr}}</div>
            </v-col>          
          </v-row>
        </v-container>
        <v-snackbar v-model="snackbar" color="teal accent-4" :timeout="2000"><span class="black--text">{{snackbarTxt}}</span></v-snackbar>
      </v-main>
      <!-- <BottomNav v-show="data.debug" @goto="menuNum"  menu="3"/> -->
      <v-footer class="transparent" style="bottom:0;position:absolute;margin:0 auto;width:100%;">
        <v-row justify="center" no-gutters>
          <v-btn
            v-for="link in data.footerLinks"
            :key="link.href"
            :href="link.href"
            target="_blank"
            color="transparent"
            variant="text"
            class="mx-2"
          >
            {{ link.name }}
          </v-btn>
        </v-row>
      </v-footer>
    </v-app>
    <Dialog @action="openDialog" :dialog="dialog" />
  </div>
</template>

<script>
import { cards, fakeplayer } from "./preset.js";

const data = { 
        debug: (process.env.NODE_ENV === 'development') ? true:false,
        playerList: [],
        lotteryList: [],
        fakeplayer, cards, 
        cardset:[],   
        banning:false, maxPlayer:25, 
        showScore: (process.env.NODE_ENV === 'development')?true:false, 
        offline: false, 
        ioRoom: null, 
        isAdmin: false,
        you:    { name:'', avatar:'', id:'' },
        winner: { name:'', avatar:'', id:'' },  
        cardinfo: { actualCard:'', cardstack:[] },
        startMenu: [], 
        score: [],
        footerLinks: [
          { name: 'Dokumentation', href: 'https://bayern.jugendschutz.de/de/Sonstiges/wenn-ich-karten.pdf' },
          { name: 'Hilfe per Email', href: 'mailto:info@aj-bayern.de?subject=Wenn-ich-Karten-Hilfe' },
          { name: 'Impressum', href: 'https://bayern.jugendschutz.de/de/impressum.php' },
          { name: 'Datenschutz', href: 'https://bayern.jugendschutz.de/de/Schwerpunkte/datenschutz.php' },
        ],
        key:'',
        allowCreate: false,
};

import Auth from './components/Auth.vue'
import TopMenu from './components/TopMenu.vue'
import BottomNav from './components/BottomNav.vue'
import Player from './components/Player.vue'
import Menu from './components/Menu.vue'
import Lottery from './components/Lottery.vue'
import Cards from './components/Cards.vue'
import Card from './components/Card.vue'
import Setup from './components/Setup.vue'
import Score from './components/Score.vue'
import Wait from './components/Wait.vue'
import Dialog from './components/Dialog.vue'

import { io } from "socket.io-client";

const protocolMode = (location.protocol=='https:') ? 'wss://wenn-ich-karten.ajbayern.de' : 'ws://wenn-ich-karten.ajbayern.de';
const srvUrl       = (process.env.NODE_ENV === 'development') ? 'ws://localhost:3002'   : protocolMode;

// console.log('dev', process.env.NODE_ENV, srvUrl)

const socket = io(srvUrl, {
  autoConnect: true, reconnection: data.debug?false:true, withCredentials: true, 
});

export default {
  name: 'App',
  data() {
    return {
      data, socket, menu:0, snackbar:false, snackbarTxt:'', dialog:{on:false,fired:true}, popupOffset:20,
    }
  },
  components: { Auth, TopMenu, BottomNav, Player, Menu, Lottery, Cards, Card, Setup, Score, Wait, Dialog, },
  created() {
    this.data.cardset = [ this.data.cards[0], this.data.cards[1], this.data.cards[2], this.data.cards[3], this.data.cards[4], this.data.cards[5] ];
    this.socketEvents();
    this.data.ioRoom = this.getQueryParameterByName('game');
    if(!this.data.ioRoom){
      this.data.key = this.getQueryParameterByName('key');
      if(this.data.key) this.socket.emit('check_key', this.data.key);
    } else this.data.allowCreate = true;
  },
  computed:{
    debugStr(){
      return JSON.stringify(this.data.cardinfo.cardstack);
    },
  },
  methods:{
    db(c){
      this.popupOffset += 15;
      if(c=='openlink') window.open(location.protocol + '//' + location.host + location.pathname +'?autologin=true&game='+this.data.ioRoom, 'id'+Math.floor(Math.random()*(9999-99+1)+99),'popup=1,toolbar=1,location=1,status=1,scrollbars=1,menubar=1,width=1100,height=800,left=1511,top='+this.popupOffset).focus();
    },
    socketEvents(){ 
      this.socket.on('your_id', (id, fn) => {
        this.data.you.id = id;
        fn(navigator.userAgent);
        
      });
      this.socket.on('accept_key', (key) => {
        this.data.key = key;
        this.data.allowCreate = true; 
      });
      socket.on('connect_error', err => {
        this.data.offline=true;
        console.log('no socket.io connection: '+ JSON.stringify(err)) 
      });
      this.socket.on('joined', (data) => {
        this.data.playerList = data.playerListArr;
        if(data.username == this.data.you.name) 
          this.menuNum(3) 
        
      });
      this.socket.on('user_left', (data) => {
        this.data.playerList = data.playerListArr;
        if(data.ending){
          this.menuNum(0)
          this.openDialog( ()=>{location.reload()}, 'Info', 'Das Spiel wurde beendet.', 'Ok', false );
        } else this.snackbarFire(data.username+' ist gegangen.')
        
      });

      this.socket.on('start_menu', (menu) => {
        this.data.startMenu = menu;
      });
      this.socket.on('goto_lottery', (plyrlist, cardstack) => {
        this.data.winner = {};
        this.data.lotteryList        = plyrlist;        
        this.data.cardinfo.cardstack = cardstack;        
        if(this.data.cardinfo.cardstack.length==0) this.goto('menu');
        else this.goto('lottery');
      });
      this.socket.on('card_stack', (winner) => {
        this.data.winner = winner;
        this.goto('cards');
      });
      this.socket.on('playcard', (cardinfo) => {
        this.data.cardinfo = cardinfo; 
        this.goto('card');
      });
      this.socket.on('score_inbetween', (bool) => {
        this.data.showScore = bool;
      });
      this.socket.on('reset_score', () => {
        this.data.score = [];
        this.data.cardinfo.cardstack = []; 
      });
      this.socket.on('goto_mainmenu', () => {
        this.data.winner = {};
        this.goto('menu');
      });
      this.socket.on('msg', (msg, ctrl=false) => {
        if(ctrl=='reload') setTimeout(()=>{location.reload()},4500);
        this.openDialog( null, 'Info', msg, 'Ok', false );
      });
      
      
      this.socket.on('card_selected',  ()=>{ this.$refs.cards.done()    });
      
      this.socket.on('reveal_card',    ()=>{ this.$refs.card.reveal()   });
      this.socket.on('trade_card',     ()=>{ this.$refs.card.trade()    });
      this.socket.on('finish_card',    (newScoreLine)=>{
        if(newScoreLine) this.data.score.push( newScoreLine );
        
        
        if(this.data.cardinfo.cardstack.length==0){
          this.menu=8; 
        } else {
          if(this.data.debug) return this.menu=8; 
          if(this.data.showScore){
            this.menu=8;
            setTimeout(()=>{ this.socket.emit('goto_lottery', this.data.cardinfo.cardstack); },3333); 
          }else this.socket.emit('goto_lottery', this.data.cardinfo.cardstack);
        }
      });
      this.socket.on('hire_card',  (user)=>{ this.$refs.card.hire(user) });

    },
    login(auth){
      this.data.you.name   = auth.name;
      this.data.you.avatar = auth.avatar;
      if(!this.data.ioRoom){ 
        this.data.ioRoom  = 'wik'+Math.floor(Math.random()*(9999-99+1)+99);
        this.data.isAdmin = true;                                                   
      }
      if(!this.data.offline) 
        this.socket.emit('join_room', auth.name, this.data.ioRoom, auth.avatar, this.data.isAdmin, this.data.key);
      else {
        this.addFakePlayer();
        
        this.menuNum(3) 
      };
    },

    upd(data){
      this.data = data;
      window.data = data;
    },
    goto(here){
      
      if(here == 'mainmenu') this.socket.emit('goto_mainmenu');
      if(here == 'gamereset') {
        this.socket.emit('goto_mainmenu');
        this.socket.emit('reset_score');
      }

      if(here == 'menu') this.menu=3;
      if(here == 'new_game') {
        
        if(this.data.cardinfo.cardstack.length==0){
          this.selectedCards(); 
          this.socket.emit('goto_lottery', this.data.cardinfo.cardstack);
        } else {
          if(confirm('Das Spiel wird fortgesetzt. Falls du die Karten zurücksetzen möchtest wähle bitte "Abbrechen".')){
            this.socket.emit('goto_lottery', this.data.cardinfo.cardstack);
          }else{
            this.selectedCards(); 
            this.socket.emit('goto_lottery', this.data.cardinfo.cardstack);
          };
        };
      };
      if(here == 'lottery') this.menu=4;
      if(here == 'cards') this.menu=5;
      if(here == 'card') this.menu=6;
      if(here == 'allgemein') this.menu=1;
      if(here == 'own') this.menu=5;
      if(here == 'wait') this.menu=9;
      if(here == 'setup') this.menu=7;
      
      if(here=='logout'){
        if(this.menu==3) this.openDialog( ()=>location.reload(),     'Bitte bestätigen', 'Alle Teilnehmer werden ausgeloggt und das Spiel wird beendet.', 'Beenden' );
        else{
          if(this.data.cardinfo.cardstack.length==0)
            this.openDialog( ()=>this.goto('gamereset'), 'Neue Runde', 'Du gelangst zurück zum Hauptmenü und startest ein neues Spiel.', 'Bestätigen' );
          else
            this.openDialog( ()=>this.goto('mainmenu'), 'Bitte bestätigen', 'Du gelangst zurück zum Hauptmenü und führst das Spiel mit der nächsten Karte weiter.', 'Hauptmenü' );
        }
      };
    },
    selectedCards(){
      this.data.cardinfo.cardstack = [].concat(   
        this.data.startMenu.includes('Allgemein')       ? this.data.cardset[0] : [],
        this.data.startMenu.includes('Digitale Spiele') ? this.data.cardset[1] : [],
        this.data.startMenu.includes('Soziale Medien')  ? this.data.cardset[2] : [],
        this.data.startMenu.includes('Smartphone')      ? this.data.cardset[3] : [],
        this.data.startMenu.includes('Glücksspiel')     ? this.data.cardset[4] : [],
        this.data.startMenu.includes('Eigene Karten')   ? this.data.cardset[5] : []
      );
    },
    menuNum(n){ 
      this.menu = n;
    },
    snippetCopy(text = location.protocol + '//' + location.host + location.pathname+'?game='+this.data.ioRoom) {
      console.log(text);
      if(typeof navigator.clipboard != 'object') window.prompt("Einladungslink kopieren: Ctrl+C, Enter", text);
      else {
        navigator.clipboard.writeText(text).then(()=>{
            this.snackbarFire('Der Einladungslink wurde in die Zwischenablage kopiert!');
        }, (err)=>{
          window.prompt("Copy to clipboard: Ctrl+C, Enter", text);
        });
      };
    },
    snackbarFire(txt){
      this.snackbar=true; this.snackbarTxt=txt;
    },
    openDialog(cb=null, hl='Achtung', txt='Bitte bestätigen', yes='Ok', no='Abbrechen'){ 
      if(!this.dialog.fired){
        if(typeof cb=='function'){
          this.dialog.fired=true;
          cb();
        }
      } else this.dialog = { on:true, fired:false, hl, txt, yes, no, cb };
    },
    getQueryParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    addFakePlayer(){
      this.data.playerList = this.data.playerList.concat(this.data.fakeplayer);
    }
  },
}
</script>










<style>
.bg{  
  background-color: #816BF4; 
  background: linear-gradient(0deg, transparent 0%, rgba(132, 123, 255, 0.7) 50%, transparent 100%), linear-gradient(0deg, rgba(120, 56, 209, 0.8) 0%, rgba(132, 123, 255, 0.5) 100%), linear-gradient(45deg, rgba(120, 56, 209, 0.5) 0%, rgba(132, 123, 255, 0.5) 100%), repeating-conic-gradient(rgba(132, 123, 255, 0.5) 0% 2.5%, transparent 2.5% 5%), repeating-conic-gradient(from 45deg, #8d42f5 0% 25%, #7838d1 0% 50%) 50%/0.75rem 0.75rem;
}
.noselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.shadow-text{
  text-shadow: 2px 0 2px black, -2px 0 2px black, 0 2px 2px black, 0 -2px 2px black;
}
.shadow-box{
  border-radius: 7px;
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.89); 
  box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.89);
}
.acid{
  font-size: 22px !important;
  text-shadow: 2px 0 2px black, -2px 0 2px black, 0 2px 2px black, 0 -2px 2px black;
  text-transform: uppercase;
  font-weight: bold !important;
  color: #37ffc8 !important;
  clear: both;
  overflow: hidden;
  white-space: nowrap;
}
.acid-btn{
  text-shadow: 1px 1px 1px black, 1px 1px 1px black, -1px -1px 1px black, -1px -1px 1px black;
  font-weight: bold !important;
  background-color: #000 !important;
  color: #37ffc8 !important;
}
.v-btn--disabled{
  text-shadow: none;
}
.outline{
  border: 5px solid teal !important;
  border-color: rgba(55, 255, 200, 1) !important;
}
</style>
