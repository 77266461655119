<template>
  <v-app-bar app color="deep-purple" dense >
    <img src="assets/aj.png" height="50" style="margin-left:-17px;margin-top:2px" />
    <v-spacer></v-spacer>
    <v-toolbar-title style="color:#fff; font-weight:bold;" class="shadow-text">WENN-ICH-KARTEN</v-toolbar-title>
  </v-app-bar>
</template>

<script> 
export default {
  name: 'Topmenu',
  data() {
    return {
    }
  },
  created() {
  },
  methods:{
  },
}
</script>

<style>

.aj-color{
  color: #68af23;
}

</style>
