<template>
  <div>
    <p class="my-10 acid text-center">{{hl}}</p>
    <div class="grid mt-n6">
      <ul class="list">
        <li @click="choose(0)" class="card" :style=" plyr?'cursor:pointer;':'cursor:not-allowed;' "></li>
        <li @click="choose(1)" class="card" :style=" plyr?'cursor:pointer;':'cursor:not-allowed;' "></li>
        <li @click="choose(2)" class="card" :style=" plyr?'cursor:pointer;':'cursor:not-allowed;' "></li>
        <li @click="choose(3)" class="card" :style=" plyr?'cursor:pointer;':'cursor:not-allowed;' "></li>
        <li @click="choose(4)" class="card" :style=" plyr?'cursor:pointer;':'cursor:not-allowed;' "></li>
        <li @click="choose(5)" class="card" :style=" plyr?'cursor:pointer;':'cursor:not-allowed;' "></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cards',
  data() {
    return {
      hl:'', plyr:false, 
    }
  },
  props: {
    data: Object,
    socket: Object,
  },
  mounted() {   
    this.plyr = this.data.winner.id==this.data.you.id ? true:false; 
    this.spread()
    if(this.plyr){
      let randArrPos = Math.floor(Math.random() * this.data.cardinfo.cardstack.length);   
      this.data.cardinfo.actualCard = this.data.cardinfo.cardstack.splice(randArrPos, 1)[0]; 
    }
  },
  methods:{
    done(){
      this.hl = this.plyr ? 'Gute Wahl!' : 'Die Karte wurde gewählt!';
      this.stack();      
      if(this.plyr) setTimeout(()=> this.socket.emit('playcard', { actualCard:this.data.cardinfo.actualCard, cardstack:this.data.cardinfo.cardstack }),  1100);
    },
    choose(){
      if(!this.plyr) return; 
      else this.socket.emit('card_selected'); 
    },
    stack(){
      $(".card").each(function(e) {
        setTimeout(function() {
          $(".card").eq(e).attr("class", "card");
        }.bind(this), e * 150);
      }.bind(this));
    },
    spread(){
      this.hl = this.plyr ? 'Ziehe eine Karte' : this.data.winner.name+' wählt seine Karte';
      $(".card").each(function(e) {
        setTimeout(function() {
          $(".card").eq(e).attr("class", "card ani" + e);
        }, e * 150)
      });
    },
  }
}
</script>

<style scoped> 
.grid {
  width: 99%;
}
.list {
  height: 95%;
  height: 350px;
  width: 400px;
  margin: auto;
  position: relative;
  list-style-type: none;
  padding-left: 0;
}
.card:hover {
  opacity: 1;
}
.card {
  opacity: 0.8;
  transition: all 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  perspective: 1000;
  -ms-transform: perspective(1000px);
  -moz-transform: perspective(1000px);
  -ms-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  background-color: #673ab7;
  -webkit-box-shadow: 2px 2px 11px 0px rgba(0,0,0,0.89); 
  box-shadow: 2px 2px 11px 0px rgba(0,0,0,0.89);
  background-image: url('https://opengameart.org/sites/default/files/card%20back%20orange.png');
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='24' viewBox='0 0 88 24'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='autumn' fill='%23ffffff' fill-opacity='0.4'%3E%3Cpath d='M10 0l30 15 2 1V2.18A10 10 0 0 0 41.76 0H39.7a8 8 0 0 1 .3 2.18v10.58L14.47 0H10zm31.76 24a10 10 0 0 0-5.29-6.76L4 1 2 0v13.82a10 10 0 0 0 5.53 8.94L10 24h4.47l-6.05-3.02A8 8 0 0 1 4 13.82V3.24l31.58 15.78A8 8 0 0 1 39.7 24h2.06zM78 24l2.47-1.24A10 10 0 0 0 86 13.82V0l-2 1-32.47 16.24A10 10 0 0 0 46.24 24h2.06a8 8 0 0 1 4.12-4.98L84 3.24v10.58a8 8 0 0 1-4.42 7.16L73.53 24H78zm0-24L48 15l-2 1V2.18A10 10 0 0 1 46.24 0h2.06a8 8 0 0 0-.3 2.18v10.58L73.53 0H78z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-image: url('https://cdn.pixabay.com/photo/2014/12/15/15/36/cloth-569222_960_720.jpg');
  background-image: url('https://cdn.pixabay.com/photo/2020/01/09/01/00/eyes-4751572_960_720.png');
  background-image: url('https://cdn.pixabay.com/photo/2021/10/06/14/11/background-6685940_960_720.jpg');
  background-image: url('https://cdn.pixabay.com/photo/2018/02/18/20/29/computer-3163436_960_720.png');
  background-size: stretch; 
  background-repeat: repeat-y;
  background-origin: center;
  background-position: 41% 23%;
  border-radius: 4px;
  border: #fff 2px solid;
  padding-top: 8%;
  text-align: center;
  
  position: absolute;
  width: 100px;
  height: 140px;
  right: 150px;
  bottom: 100px;
  z-index: 2;
}
.card:nth-child(5n) {
  margin-right: 0;
}
.card.ani0 {
  right: 290px;
  bottom: 58%;
}
.card.ani1 {
  right: 145px;
  bottom: 58%;
}
.card.ani2 {
  right: 5px;
  bottom: 58%;
}
.card.ani3 {
  right: 290px;
  bottom: 1%;
}
.card.ani4 {
  right: 145px;
  bottom: 1%;
}
.card.ani5 {
  right: 5px;
  bottom: 1%;
}
</style>
