<template>
  <div style="height: 100%;">
    <Wait v-if="wait" hl="startet..." />
    <div id="wheel" v-if="!wait">
      <p class="my-16 acid text-center">{{hl}}</p>
      
      <carousel-3d style="pointer-events:none;" ref="lottoslide" :perspective="60" :controls-visible="false" height="160" width="160" display="7" animation-speed="250">
        <slide v-for="(slide, i) in slides" :index="i" :key="i" :style="{background: 'lightcyan url('+slide.avatar+')', backgroundRepeat:'no-repeat', backgroundPosition:'50% 50%', 'background-size':'99% !important' }">
          <h1 class="text-center mb-2" style="bottom:0px; position:absolute; width:100%; color:#fff; text-shadow:2px 0 2px black, -2px 0 2px black, 0 2px 2px black, 0 -2px 2px black; line-height:0.8em; padding:5px; font-size:25px;">{{slide.name}}</h1>
        </slide>
        <v-icon size="120" v-if="crown" color="info" class="shadow-text" style="margin:-65px 0 0 18px; z-index: 9999;">mdi-crown-outline</v-icon>
      </carousel-3d>

      <v-row justify="center" class="pt-5">
        <v-btn v-if=" data.winner.id==data.you.id " class="ml-3 my-3 acid-btn" @click="goto">
          <v-icon dark left>mdi-skip-next</v-icon> Bereit
        </v-btn>
      </v-row>

    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'; 
import Wait from './Wait.vue'

export default {
  name: 'Menu',
  data() {
    return {
      diceBtn: true, crown: false, hl: '', isPlayer:false,
      count: 0,
      startTime: 50, 
      slides: [],
      wait: this.data.debug ? false:true,
    }
  },
  props: {
    data: Object,
    socket: Object,
  },
  components: {
    Carousel3d, Slide, Wait,
  },
  mounted(){
    this.data.debug ? this.lottery() : 
    setTimeout(()=>{
      this.wait=false;
      this.$nextTick(() => {
        this.lottery()
      });
    },3100);
  },
  methods:{
    goto(){
      this.socket.emit('card_stack', this.data.you);
    },
    lottery(){
      this.count          = 0; 
      this.startTime      = 50;
      this.crown          = false;
      this.hl             = 'Seid ihr bereit?';
      this.isPlayer  = false;
      if(this.data.lotteryList.length < 2) return; 
      this.slides = this.data.lotteryList;
      this.trigger(); 
    },
    trigger() {
      
      if(typeof this.$refs.lottoslide == 'undefined') return;
      this.$refs.lottoslide.goSlide( this.count ); 

      this.startTime *= 1.09; 
      
      if(this.startTime>=500){ 
        
        setTimeout(()=>{
          this.hl = this.slides[this.count].name + ' ist dran!';
          this.crown = true;
        },999);
        
        this.data.winner.id = this.slides[this.count].id;        
        
        this.$emit('upd',this.data)
      }else{ 
        this.count++;
        if( this.count==this.slides.length ) this.count=0; 
        setTimeout(this.trigger, this.startTime);
      }
    },
  },
}
</script>

<style scoped>
.carousel-3d-container[data-v-07917306] {
    overflow: unset;
}
.carousel-3d-slide {
    border-radius: 50%;
    border: solid black 3px !important;
    background-color: lightcyan;
    text-align: center;
}
.preset{
  cursor: pointer;
}
</style>
