


<template>
  <v-app id="app">
    <TopMenu />
    <v-main>
      <v-container class="bg" fill-height fluid>
        <v-row justify="center" class="ma-1">
          <v-col xs="12" md="6" lg="4">
            <v-card rounded="lg" class="card-login" style="background-color:#ffffff17;">
              <v-card-title>{{hl}}</v-card-title> 
              <v-divider></v-divider>
              <v-card-text class="pa-5" v-if="data.allowCreate">
                <v-row justify="center" align="center">
                  <v-col cols="12" sm="4">
                    <v-avatar size="100%">
                      <img v-show="showAvatar" :src="avatar" @load="showLoader=false;showAvatar=true;" class="avatar" ref="avatar" width="99%" style="border:black 2px solid; border-radius:50%; max-width:200px; min-height:100px; cursor:pointer; " @click="reavatar" />
                      <v-progress-circular v-show="showLoader" :size="50" color="primary" indeterminate></v-progress-circular>
                    </v-avatar>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <h3>Wähle Avatar und Name:</h3><br>
                    <v-text-field @keyup.enter="start" outlined single-line color="green darken-1" v-model="username" :label="defaultname" background-color="white"></v-text-field>
                    <!-- <v-text-field @keyup.enter="start" outlined single-line color="green darken-1" v-model="licensekey" label="Lizenzschlüssel" background-color="white"></v-text-field> -->
                    <v-btn large color="primary" @click="start">
                       <v-icon dark left>mdi-menu-right-outline</v-icon> Start
                    </v-btn>
                  </v-col>
                </v-row>          
              </v-card-text>
            </v-card>
          </v-col>          
        </v-row>
        <v-footer class="transparent" style="bottom:0;position:absolute;margin:0 auto;width:100%;">
          <v-row justify="center" no-gutters>
            <v-btn
              v-for="link in data.footerLinks"
              :key="link.href"
              :href="link.href"
              color="transparent"
              variant="text"
              class="mx-2"
            >
              {{ link.name }}
            </v-btn>
          </v-row>
        </v-footer>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const robohashAvatars = require("robohash-avatars");
import TopMenu from './TopMenu.vue'

export default {
  name: 'Auth',
  components: { TopMenu },
  data() {
    return {
      rand: Math.floor(Math.random()*(9999-99+1)+99),
      set: Math.floor(Math.random()*(3)+1),
      username: "", 
      licensekey: "",
      defaultname: "",
      avatar:"",
      showAvatar:false,showLoader:true,
    }
  },
  props:{
    hl:{ type: String, default: 'Neues Spiel erstellen' },
    admin: Boolean,
    autologin: Boolean,
    data: Object,
  },
  created(){  
    this.defaultname = (this.admin ? "Admin" : "Nickname") +this.rand;
    this.avatar="https://test.lukundcoco.de/"+Math.floor(Math.random()*(9999-99+1)+99)+"?set=set"+this.set;
  },
  mounted() {
    this.fakeLoad();
    if(this.autologin) this.start();
  },
  methods: {
    createAvatar(set=Math.floor(Math.random()*(4)+1), unique=Math.floor(Math.random()*(9999-99+1)+99)){
      let theme = ['', 'Robots', 'Monsters', 'DisembodiedHeads', 'Kittens'];
      return robohashAvatars.generateAvatar({
        username: unique, 
        characters: robohashAvatars.CharacterSets[ theme[set] ],
        background: null, 
        height: 150, width: 150,
      }); 
    },
    reavatar(){
      this.fakeLoad();
      this.avatar="https://test.lukundcoco.de/"+Math.floor(Math.random()*(99999-99+1)+99)+"?set=set"+this.set;
    },
    start(){
      if(this.username=="") this.username=this.defaultname;
      this.$emit('login',{
        name: this.username,
        avatar: this.avatar,
      });
    },
    fakeLoad(t=900){
      this.showAvatar=false;
      this.showLoader=true;
      setTimeout(()=>{
        this.showAvatar=true;
        this.showLoader=false;
      },t);

    },
  }
}
</script>

<style scoped> 
.avatar{
  background-color: #fff;
}
.avatar:hover{
  background-color: #604fc1;
}
</style>
