

<template>
  <v-list :height=" data.isAdmin?'365px':'425px' " :class="['overflow-y-auto', 'pr-2', data.isAdmin&&data.playerList.length<=2?'arrow':'']" tow-line style="border:1px solid black !important; border-radius:8px;" color="deep-purple" outlined>
    <template v-for="item in playerOnline"><!-- <v-divider></v-divider> -->
      <v-list-item class="ma-1" style="background-color: #fff; border-radius: 30px 5px 5px 30px !important; border: 2px solid;" v-if="item.name" :key="item.name">
        <v-list-item-avatar style="margin-left:-10px;">
          <v-img :src="item.avatar" style="border:2px solid;"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>

          <v-tooltip bottom v-if="data.isAdmin && data.debug">
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-list-item-title v-html=" (data.you.id==item.id) ? 'Du' : item.name "></v-list-item-title>           
                <v-list-item-subtitle v-html="new Date(item.ts).toLocaleTimeString('de-De')"></v-list-item-subtitle>
              </span>
            </template>
            <span>{{item.id}} <br> {{item.name}} <br> {{item.roomId}} </span>
          </v-tooltip>

          <v-list-item-title v-else v-html=" (data.you.id==item.id) ? 'Du' : item.name "></v-list-item-title>           
        </v-list-item-content>
        <v-list-item-action v-if="data.isAdmin && data.you.id!=item.id && data.banning">
          <v-btn icon @click="ban(item.id)">
            <v-icon color="red">mdi-cancel</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-list>
</template>

<script> 
export default {
  name: 'Player',
  props: {
    data: Object,
    socket: Object,
  },
  created() {
    
  },
  computed: {
    playerOnline () {
      let tmp = this.data.playerList; 
      tmp = tmp.sort((a, b) => {      
        return (a.ts > b.ts) ? 1 : -1
      });
      let me=null;
      tmp.forEach( (el,idx,obj) => {
        if(el.id == this.data.you.id) {   
          me = el;                        
          obj.splice(idx, 1);             
        }
      });
      if(me) tmp.unshift(me);        
      return tmp;
    },
  },
  methods:{
    goto(here){
      this.$emit('goto',here)
    },
    ban(id){
      if(confirm('Möchtest du den Spieler wirklich aus dem Raum entfernen?')) this.socket.emit('ban_id',id);
    },
  }
}
</script>

<style>
.arrow{
  background-image: url('https://cdn.pixabay.com/photo/2012/04/11/10/51/arrow-27467_960_720.png') !important;
  background-repeat: no-repeat !important;
  background-size: 65% !important;
  background-origin: center !important;
  background-position: 30% 80% !important;
}
</style>
