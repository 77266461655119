


<template>
 <div>
  <v-subheader class="acid ml-n4">Spielauswahl</v-subheader>
  <v-sheet color="deep-purple" elevation="3" height="350" rounded class="overflow-y-auto overflow-x-hidden pa-3">
    <v-row dense>
      <v-col v-for="card in cards" :key="card.id" :cols="card.flex">
        <v-card @click="lazyGoto(card.title)" height="158px" outlined :class="[data.startMenu.includes(card.title)?'outline':'', data.isAdmin?'':'noclick' ]">
          <v-img :src=" 'assets/'+card.id+'.png' " class="deep-purple darken-1 white--text align-end" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" height="100%">
            <v-card-title style="font-size:16px; font-weight:bold;" v-html=" card.title + (data.debug?' ('+data.cardset[card.id].length+')':'')"></v-card-title>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </v-sheet> 
  <v-row justify="center">
    <div v-show=" data.isAdmin && data.startMenu.length>0 " class="mt-6 shadow-box">
      <v-btn @click="goto('setup')" icon tile class="acid-btn" style="background-color:#000 !important; border-right: 1px solid #37ffc8; border-radius: 5px 0 0 5px;">
        <v-icon dark>mdi-cog</v-icon>
      </v-btn>
      <v-btn @click="goto" :disabled="data.playerList.length<2" style="background:transparent" class="acid-btn">
        <v-icon dark left>mdi-menu-right-outline</v-icon> Starten
      </v-btn>
    </div>    
  </v-row>
 </div>
</template>

<script>
export default {
  name: 'Menu',
  data() {
    return {
      selected: '',
      cards: [
        { id:0, title: 'Allgemein', src: 'https://cdn.pixabay.com/photo/2017/08/04/13/22/hooded-man-2580085_960_720.jpg', flex: 4 },
        { id:1, title: 'Digitale Spiele', src: 'https://cdn.pixabay.com/photo/2015/04/14/13/38/games-722136_960_720.jpg', flex: 4 },
        { id:2, title: 'Soziale Medien', src: 'https://cdn.pixabay.com/photo/2014/03/22/22/17/phone-292994__340.jpg', flex: 4 },
        { id:3, title: 'Smartphone', src: 'https://cdn.pixabay.com/photo/2016/11/22/06/32/girl-1848478__340.jpg', flex: 4 },
        { id:4, title: 'Glücksspiel', src: 'https://cdn.pixabay.com/photo/2018/12/23/16/51/poker-dice-3891482__340.jpg', flex: 4 },
        { id:5, title: 'Eigene Karten', src: 'https://cdn.pixabay.com/photo/2021/11/13/06/31/question-mark-6790598__340.jpg', flex: 4 },
      ],
    }
  },
  props: {
    data: Object,
    socket: Object,
  },
  methods:{
    goto(to=null){
      if(to=='setup') return this.$emit('goto','setup');
      
      if(this.data.offline) this.$emit('goto','lottery');
      else{
        
        this.$emit('goto','new_game');
      }
    },
    lazyGoto(title){
      
      if(!this.data.isAdmin) return; 

      if( this.data.startMenu.includes(title) ){
        this.remove(this.data.startMenu, title)
      }else{
        this.data.startMenu.push(title)
      }
      
      this.socket.emit('start_menu', this.data.startMenu);
    },
    remove(arr, what) {
      let found = arr.indexOf(what);
      while (found !== -1) {
          arr.splice(found, 1);
          found = arr.indexOf(what);
      }
    },
  }
}
</script>

<style scoped> 
.noclick{
  cursor: not-allowed;
}
</style>
