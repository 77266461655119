<template>
  <div>
    <p class="mt-12 mb-6 acid text-center">{{hl}}</p>
    <v-sheet v-if="hiddenCard" color="deep-purple" elevation="3" rounded class="overflow-y-auto overflow-x-hidden pa-3 cardtop cardbg" style="border: #fff 4px solid !important;">
    </v-sheet>

    <v-sheet v-if="!hiddenCard" color="deep-purple" elevation="3" rounded class="overflow-y-auto overflow-x-hidden pa-3 cardtop">
      <div style="background-image:url('assets/ill1.png'); background-size:90%; background-position:60% 30%; background-origin:center; width:30%; min-width: 270px; max-width: 355px; height:50px; position:absolute; margin-top:10px"></div>
      <div style="background-image:url('assets/ill2.png'); background-size:90%; background-position:60% 30%; background-origin:center; width:30%; min-width: 270px; max-width: 355px; height:50px; position:absolute; margin-top:150px"></div>
      <div style="border:#bbb 1px solid; width:100%; height:200px; border-radius:7px;">
        <v-row class="fill-height mt-0" align="center" justify="center">
          <div class="col-12 px-5 text-center" :style="{ fontSize: cardText.length>100?'13px':cardText.length>50?'15px':'18px', fontWeight:'bold', color:'#fff' }">{{cardText}}</div>
        </v-row>
      </div>
    </v-sheet>

    <v-row justify="center" class="pt-5">
      <v-btn v-if="tradeBtn" :disabled="tradeDis" small class="mt-4" color="grey lighten-1" @click=" socket.emit('trade_card') ">
        <v-icon dark left>mdi-hand-heart-outline</v-icon> Tauschen
      </v-btn>
      <v-btn v-if="revealBtn" class="ml-3 mt-3" color="error" @click=" socket.emit('reveal_card') ">
        <v-icon dark left>mdi-eye-outline</v-icon> Aufdecken
      </v-btn>
      <v-btn v-if="doneBtn" :disabled="doneDis" class="ml-3 mt-3" color="success" @click="done">
        <v-icon dark left>mdi-check-outline</v-icon> Fertig
      </v-btn>
      <v-btn v-if="hireBtn" class="ml-3 mt-3" color="error" @click=" socket.emit('hire_card', data.you) ">
        <v-icon dark left>mdi-hand-peace</v-icon> Anfordern
      </v-btn>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Card',
  data() {
    return {
      hiddenCard: true, tradeDis:true,
      tradeBtn:false, revealBtn:false, doneBtn:false, doneDis:true, hireBtn:false,
      hl:'',
      cardText:'Ich habe schon mal (noch nicht) an Glücksspielen im Internet teilgenommen, weil...',
      timer:0,
      plyrName:'', tradeTimeout:null,
    }
  },
  props: {
    data: Object,
    socket: Object,
  },
  created() {
    this.plyrName = this.data.winner.name.substring(0, 16); 
    this.plyr     = this.data.winner.id==this.data.you.id ? true:false; 
    this.cardText = this.data.cardinfo.actualCard; 
  },
  mounted() {
    this.hl        = this.plyr ? 'Deine Karte...' : this.plyrName+' ist dran.';

    setTimeout(()=>{  
      if(this.plyr) this.hiddenCard=!this.hiddenCard;
      this.revealBtn = this.plyr ? true : false;  
      this.hl        = this.plyr ? '...Nur für dich sichtbar' : this.plyrName+' liest...';
    },1111);

    setInterval(()=>{
      this.timer++;
    },999);
  },
  methods: {
    reveal(){ 
      this.hl = 'Beantworte die Karte!'
      this.hl = this.plyr ? 'Beantworte die Karte!' : this.plyrName+'\'s Karte:';
      this.hiddenCard=!this.hiddenCard;
      setTimeout(()=>{
        this.hiddenCard=false; 
      },333)
      setTimeout(()=>{
        this.doneDis=false;   
        this.tradeDis=false;  
      },this.data.debug?111:3999)
      this.revealBtn=false;
      this.doneBtn  = this.plyr ? true : false;
      this.tradeBtn = this.plyr ? true : false;
    },
    done(){                                                     
      if(confirm('Bist du sicher? Zeit bisher: '+this.timer+' Sek.')){
        this.socket.emit('finish_card', {
          name:   this.data.winner.name,
          avatar: this.data.winner.avatar,
          card:   this.data.cardinfo.actualCard,
          timer:  this.timer,
          ts:     Date.now(),
        }); 
      }
    },
    trade(){ 
      if(this.plyr) this.hiddenCard=true;
      this.hl = this.plyr ? 'Du hast die Karte abgelehnt!' : this.plyrName+' möchte die Karte abgeben.';
      this.doneBtn=false
      this.tradeDis=true
      this.hireBtn = this.plyr ? false : true;
      this.tradeTimeout = setTimeout(()=>{
        this.socket.emit('finish_card', null);
      }, this.data.debug?3333:20000);
    },
    hire(winner){ 
      clearTimeout(this.tradeTimeout);
      this.hireBtn = false;
      this.data.winner = winner;
      this.timer = 0;
      if(winner.id == this.data.you.id){
        this.hl = 'Beantworte die Karte!';
        this.doneBtn = true;
      }else{
        this.hl = winner.name+' darf die Karte beantworten.';
      }
    },
  },
}
</script>

<style scoped> 
.cardtop{
  margin: 0 auto;
  width: 50%; 
  max-width: 380px; 
  min-width: 300px;
}
.cardbg{
  height:224px;
  background-image: url('https://cdn.pixabay.com/photo/2018/02/18/20/29/computer-3163436_960_720.png');
  background-size: stretch; 
  background-repeat: repeat-y;
  background-origin: center;
  background-position: 41% 23%;
  background-color: #673ab7;
  -webkit-box-shadow: 2px 2px 11px 0px rgba(0,0,0,0.89); 
  box-shadow: 2px 2px 11px 0px rgba(0,0,0,0.89);
}
</style>
